body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.App {
  text-align: center;
  min-height: 100%;
}

.App-link {
  color: #f0bf3a;
  transition: 0.5s; }
  a:hover, a:active, a:focus {
    color: #d3a015;
    outline: none;
    text-decoration: none !important;
}

.App-header {
  min-height: 100vh;
  max-height: 150vh;
  background-image: url(/static/media/mesand.01de8102.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}



