
.App {
  text-align: center;
  min-height: 100%;
}

.App-link {
  color: #f0bf3a;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s; }
  a:hover, a:active, a:focus {
    color: #d3a015;
    outline: none;
    text-decoration: none !important;
}

.App-header {
  min-height: 100vh;
  max-height: 150vh;
  background-image: url(images/mesand.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}


